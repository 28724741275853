import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const PrivacyPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <div className="bg-gray-50 py-12">
      <div className="w-11/12 max-w-2xl mx-auto">
        <h2 className="text-site-lightblue text-3xl text-center font-bold mb-6">Privacy Policy</h2>
        <p className='text-gray-800 mb-4'>SECTION 1 - WHAT DO WE DO WITH YOUR INFORMATION?</p>
        <p className='text-gray-800 mb-4'>When you visit our website, we collect the personal information you give us such as your name, address and email address. When you browse our website, we also automatically receive your computer’s internet protocol (IP) address in order to provide us with information that helps us learn about your browser and operating system. Email marketing (if applicable): With your permission, we may send you emails about our website, new services and other updates.</p>

        <p className='text-gray-800 mb-4'>SECTION 2 - CONSENT</p>
        <p className='text-gray-800 mb-4'>How do you get my consent? When you provide us with personal information to complete a transaction, verify your credit card, place an order, we imply that you consent to our collecting it and using it for that specific reason only. If we ask for your personal information for a secondary reason, like marketing, we will either ask you directly for your expressed consent, or provide you with an opportunity to say no.</p>
        <p className='text-gray-800 mb-4'>How do I withdraw my consent? If after you opt-in, you change your mind, you may withdraw your consent for us to contact you, for the continued collection, use or disclosure of your information, at anytime, by contacting us at david@itproactive.com or mailing us at: IT Proactive 3420 Bristol St., Suite 601, Costa Mesa, CA, 92626, United States.</p>

        <p className='text-gray-800 mb-4'>SECTION 3 - DISCLOSURE</p>
        <p className='text-gray-800 mb-4'>We may disclose your personal information if we are required by law to do so or if you violate our Terms of Service.</p>

        <p className='text-gray-800 mb-4'>SECTION 4 - THIRD-PARTY SERVICES</p>
        <p className='text-gray-800 mb-4'>In general, the third-party providers used by us will only collect, use and disclose your information to the extent necessary to allow them to perform the services they provide to us. However, certain third-party service providers have their own privacy policies. For these providers, we recommend that you read their privacy policies so you can understand the manner in which your personal information will be handled by these providers. In particular, remember that certain providers may be located in or have facilities that are located in a different jurisdiction than either you or us. So if you elect to proceed with a transaction that involves the services of a third-party service provider, then your information may become subject to the laws of the jurisdiction(s) in which that service provider or its facilities are located. Once you leave our website or are redirected to a third-party website or application, you are no longer governed by this Privacy Policy or our website’s Terms of Service.</p>

        <p className='text-gray-800 mb-4'>Links</p>
        <p className='text-gray-800 mb-4'>When you click on links on our website, they may direct you away from our site. We are not responsible for the privacy practices of other sites and encourage you to read their privacy statements.</p>

        <p className='text-gray-800 mb-4'>SECTION 5 - SECURITY</p>
        <p className='text-gray-800 mb-4'>To protect your personal information, we take reasonable precautions and follow industry best practices to make sure it is not inappropriately lost, misused, accessed, disclosed, altered or destroyed.</p>

        <p className='text-gray-800 mb-4'>SECTION 6 - AGE OF CONSENT</p>
        <p className='text-gray-800 mb-4'>By using this site, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>

        <p className='text-gray-800 mb-4'>SECTION 7 - CHANGES TO THIS PRIVACY POLICY</p>
        <p className='text-gray-800 mb-4'>We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it. If our company is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products or services to you.</p>

        <p className='text-gray-800 mb-4'>QUESTIONS AND CONTACT INFORMATION</p>
        <p className='text-gray-800 mb-4'>If you would like to: access, correct, amend or delete any personal information we have about you, register a complaint, or simply want more information contact david@itproactive.com or by mail at IT Proactive:</p>
        <p className='text-gray-800 mb-4'>[Re: Privacy Policy]</p>
        <p className='text-gray-800 mb-4'>[3420 Bristol St., Suite 601, Costa Mesa, CA, 92626, United States]</p>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
